.mobileNav{
  >ul{
    margin: 0;
    padding: 0;
    >li{
      list-style-type: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.168);
      padding-bottom: 5px;
      text-align: right;
      padding: 5px 30px 5px 5px;
    }
    >li:hover{
      color:rgb(255, 0, 0);
      cursor: pointer;
    }
  }
}